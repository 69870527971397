/*************************
 * How to use this thing *
 *************************
 *
 * create an overlay:
 *        <div class="sbf-overlay-content" id="my-overlay-1">
 *            CONTENT
 *            <!-- optional close button (top right corner): -->
 *            <a class="close-button" href="#">Close</a>
 *        </div>
 *
 * create a trigger:
 *     <a href="#" class="sbf-overlay-trigger" rel="#my-overlay-1">Show Overlay</a>
 *
 * include overlay.js and overlay.css in your view
 *
 * that's it. it finds your triggers automatically and wires them to your overlays.
 *
 * to close the overlay, just call sbf.overlay.hide();
 *
 * to open an overlay without a trigger link, use sbf.overlay.show('#my-overlay-1');
 *
 * to check if an overlay is currently open, use sbf.overlay.isShown()
 *
 * to get the current content element, use sbf.overlay.getCurrentContentElement(); // returns jQuery object
 *
 * to indicate the overlay content is loading, use sbf.overlay.indicateLoading() and sbf.overlay.indicateFinishedLoading()
 *
 * IMPORTANT: when using sbf.overlay.show() in a 'click' fxn in IE, be sure to return false after
 * calling .show(), or else the click will propogate through to the body (see the conditional in
 * the init function) and immediately hide the overlay as if you had clicked outside it.
 *
 * use custom events if needed:
 *     $('#my-overlay-1').bind('sbf.beforeShow', function (event) { alert('about to show the overlay!'); });
 *     // "this" will refer to the sbf-overlay-content element within callbacks
 *
 * custom events available:
 *     sbf.beforeShow, sbf.afterShow, sbf.beforeHide, sbf.afterHide
 *
 */

!(function (w, $) {
  if (!w.sbf) {
    w.sbf = {};
  }

  let $sbf_overlay_div;
  let $body;
  const content_cache = [];
  let $current_content;
  const form_values_hash = new Object();
  let is_shown_ = false;
  const KEYCODE_ESCAPE = 27;
  let $quick_message_content;
  const ua = navigator.userAgent.toLowerCase();
  const isAndroid = ua.indexOf('android') > -1;
  const isIPad = ua.indexOf('ipad') > -1;

  function escape_key_listener (e) {
    if (sbf.overlay.isShown() && e.keyCode === KEYCODE_ESCAPE) {
      sbf.overlay.hide();
    }
  }

  function overlay_click_listener (e) {
    sbf.overlay.hide();
  }

  function overlay_content_click_listener (e) {
    // prevent click on overlay content from closing overlay
    e.stopPropagation();
  }

  function position_current_content () {
    if ($current_content) {
      const content_height = $current_content.outerHeight(false);
      const overlay_div_height = $sbf_overlay_div.height();

      if (content_height + 40 < overlay_div_height) {
        // center content vertically
        var margin_height = (overlay_div_height - content_height) / 2;
        $current_content.css('marginTop', margin_height + 'px').css('marginBottom', margin_height + 'px');
      } else {
        // move the content to the top of the page
        var margin_height = 20;
        $current_content.css('marginTop', margin_height + 'px');
      }
    }
  }

  function scrollTo (element) {
    // Only scroll if it is not already on the screen
    if (element.offset().top < $(window).scrollTop()) {
      $('html, body').animate({ scrollTop: element.offset().top }, 100);
    }
  }

  sbf.overlay = {

    init: function () {
      $body = $('body');
      $sbf_overlay_div = $('<div id="sbf-overlay"><div class=inner></div></div>').appendTo($body);
      $sbf_overlay_div.bind('click', overlay_click_listener);

      $(document).bind('keyup', escape_key_listener);
      $(window).bind('resize', function () {
        if (sbf.overlay != undefined && sbf.overlay.isShown()) {
          position_current_content();
        }
      });

      // wire up all the links

      $('.sbf-overlay-trigger').each(function () {
        const $this = $(this);
        const rel = $this.attr('rel');

        if (rel) {
          $this.bind('click', function (click) {
            click.preventDefault();
            click.stopPropagation();
            // stupid stuipid ie does stupid things, so we need this:
            if (sbf.overlay.isShown()) {
              sbf.overlay.hide();
              return false;
            }
            sbf.overlay.show(rel);
          });
        } else {
          console.warn('Overlay missing rel attribute');
        }
      });
    },

    /**
         * shows an element in the overlay view
         * @param selector the selector string for the element to be shown
         * @return jQuery result of $(selector)
         **/
    show: function (selector) {
      // gracefully handle cases where people don't pass a string
      if (selector.jquery) {
        // someone passed in a jquery object instead of a selector string
        selector = '#' + selector.attr('id');
      } else if (selector.nodeName) {
        // someone passed in an actual element instead of a selector string
        selector = '#' + nodeName.id;
      }
      sbf.overlay.hide();
      // use cached object if possible
      if (content_cache[selector]) {
        // this overlay content is already initialized
        $current_content = content_cache[selector];
      } else {
        // this overlay content has not been initialized
        $current_content = content_cache[selector] = $(selector);
        $current_content.appendTo($sbf_overlay_div.find('div.inner'));

        // this listener simply prevents clicks on the content from propagating to the
        // overlay background (which would close the overlay otherwise)
        $current_content.bind('click', overlay_content_click_listener);
      }

      $('a.close-button', $current_content).bind('click', function (click) {
        click.preventDefault();
        sbf.overlay.hide();
      });

      $current_content.trigger('sbf.beforeShow');

      // don't make content visible before it has been positioned
      $current_content.css('visibility', 'hidden');

      $current_content.addClass('sbf-overlay-content-current');

      $current_content.show();
      $sbf_overlay_div.show();
      position_current_content();
      $current_content.css('visibility', 'visible');
      is_shown_ = true;

      if (isAndroid || isIPad) {
        scrollTo($current_content);
      }

      // Save all form values
      if ($current_content.hasClass('form-memory')) {
        const $form = $current_content.find('form');

        form_inputs = new Object();
        $form.find('input').each(function () {
          if ($(this).is(':checkbox')) {
            form_inputs[$(this).attr('id')] = $(this).is(':checked');
          } else {
            form_inputs[$(this).attr('id')] = $(this).val();
          }
        });

        form_selects = new Object();
        $form.find('select').each(function () {
          form_selects[$(this).attr('id')] = $(this).html();
        });

        // Store off the form values
        form_values = new Object();
        form_values.inputs = form_inputs;
        form_values.selects = form_selects;
        form_values_hash[$form.attr('id')] = form_values;
      }

      $current_content.trigger('sbf.afterShow');

      return $current_content;
    },

    close: function (reset_form) {
      sbf.overlay.hide(reset_form);
    },

    hide: function (reset_form) {
      if ($current_content != null) {
        $current_content.trigger('sbf.beforeHide');
        $sbf_overlay_div.hide();
        $current_content.hide();
        $current_content.removeClass('sbf-overlay-content-current');
        $current_content.css('visibility', '');
        is_shown_ = false;
        sbf.overlay.indicateFinishedLoading();

        // unbind close click
        $('a.close-button', $current_content).unbind('click');

        // If reset_form is passed in as true, then reset the state of all form elements in the overlay
        // Pass in "false" when the overlay is being "successfully closed", i.e., you're closing it on successful save of the form
        // This may fall apart if there are multiple forms in the overlay?
        reset_form = (typeof reset_form === 'undefined') ? true : reset_form; // Default reset_form to true
        if (reset_form === true) {
          if ($current_content.hasClass('form-memory')) {
            const $form = $current_content.find('form');
            const form_values = form_values_hash[$form.attr('id')];
            const form_inputs = form_values.inputs;
            const form_selects = form_values.selects;

            $form.find('input').each(function () {
              if ($(this).is(':checkbox')) {
                $(this).prop('checked', form_inputs[$(this).attr('id')]);
              } else {
                $(this).val(form_inputs[$(this).attr('id')]);
              }
            });

            $form.find('select').each(function () {
              $(this).html(form_selects[$(this).attr('id')]);
            });

            // Reactivate triggers
            $form.find('input').each(function () {
              $(this).change();
            });
            $form.find('select').each(function () {
              $(this).change();
            });
          }
        }

        $current_content.trigger('sbf.afterHide');
      }
      $current_content = null;
    },

    isShown: function () {
      return is_shown_;
    },

    getCurrentContentElement: function () {
      return $current_content;
    },

    refreshPosition: function () {
      position_current_content();
    },

    // overlay a spinner on the content
    indicateLoading: function () {
      if ($current_content) {
        $current_content.addClass('sbf-overlay-loading');

        if (!$current_content.find('div.loading-indicator').length) {
          $current_content.append('<div class="loading-indicator"><div class="mask"></div><div class="spinner"></div></div>');
        }
      }
    },

    isLoading: function () {
      if ($current_content != undefined) {
        return $current_content.hasClass('sbf-overlay-loading');
      }
      return false;
    },

    // remove spinner overlay
    indicateFinishedLoading: function () {
      if ($current_content != undefined) {
        $current_content.removeClass('sbf-overlay-loading');
      }
    },

    showQuickMessage: function (title, message, hide_close_button) {
      const quick_message_overlay_id = 'sbf-overlay-quick-message-content';
      if (!$quick_message_content) {
        $quick_message_content = $('<div class="sbf-overlay-content" id="sbf-overlay-quick-message-content"><h3></h3><div></div><a class="close-button" href="#">Close</a></div>');
        $body.append($quick_message_content);
      } else {
        $('#sbf-overlay-quick-message-content').html('<h3></h3><div></div><a class="close-button" href="#">Close</a>');
      }

      if (title && title.length) {
        $quick_message_content.children('h3').first().html(title).show();
      } else {
        $quick_message_content.children('h3').first().html('').hide();
      }

      $quick_message_content.children('div').first().html(message);

      if (hide_close_button) {
        $quick_message_content.children('a.close-button').hide();
      } else {
        $quick_message_content.children('a.close-button').show();
      }

      sbf.overlay.show('#' + quick_message_overlay_id);
    }
  };

  // initialize on document ready event
  $(sbf.overlay.init);
}(window, jQuery));
